
*{
    margin: 0;
    padding: 0;
}


html{
    scroll-behavior: smooth;
}

/*-----Nav Bar ----*/
.nav-bar{
    margin: 1px 0px;
    position: sticky;
    top: 0;
    z-index: 12;
    background: #fff;
    text-align: right;
}
.navbar-brand {
    font-size: 20px;
    padding-left: 20px;
}

.nav-link{
    color: #5d5c61;
    font-weight: 600;
    font-size: 16px;
    float: right;
    text-align: left;
    margin-right: 20px;
}

#nav-bar a:hover{
    color: #557a95;
}

/*---Main Page----*/
#slider{
    width: 100%;

}
.carousel-item{
    display: block;
    width: 100%;
    height: 100%;
}
.carousel-item img {
    width: 100vw;
    height: 95vh;
    filter: blur(30px);
    filter: brightness(60%);
}
.carousel-caption{
    top: 20%;
    bottom: initial!important;
}
/*FORMAT THIS PART */

.carousel-caption h5{
    color: #ccc;
    font-size: 32px;
        text-shadow: 0px 0px 5px black;

}
.carousel-item h2{
    color: #fff;
    font-size: 6em;
    letter-spacing: 0.07em;
    line-height: 1.7;
    font-family: 'Quicksand', sans-serif;
    text-transform: uppercase;
    text-shadow: 0px 0px 5px black;
}


.carousel-caption button:hover{
    animation: end;
}


.carousel-caption .btn-info {
  border-width: 2px;
  background-color: transparent;
  font-weight: 400;
  filter: alpha(opacity=80);
  padding: 8px 16px;
  border-color: #fff;
  color: #fff;
    font-size: 20px;
}



.carousel-caption .btn-info:hover, .btn-info:focus, .btn-info:active, .btn.active, .open > .btn.dropdown-toggle {
  background-color: transparent;
  color: #777777;
  border-color: #777777;
}

.carousel-caption .btn-info {
  border-radius: 2px;
  border-width: 1px;
  font-family: "Verlag-Book","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 600;
  line-height: 1;
}

/*--------Mission--------*/
#mission{
    padding-top: 120px;
    padding-bottom: 120px;
    color: #5d5c61;
}

#mission .btn{
    color: #fff;
    background-color: #557a95;
    border: none;
    border-radius: 6px;
    margin: 20px;
}

#mission .btn:hover{
    background-color: #666;
    cursor: pointer;
    transition: 0.2s;
}

.mission-content{
    padding-top: 20px;
    font-size: 17px;
}
.process{
    height: 120px;
    font-size: 17px;
}

.process .feature-left{
    height: 120px;
    width: 20%;
    float: left;
}

.process .feature-left .fa{
    padding-top: 13px;
    font-size: 50px;
    color: #5d5c61;
}

.process .feature-right{
    height: 120px;
    width: 80%;
    float: right;
}

.process .feature-right h4{
    padding-top: 10px;
    padding-left: 15px;
    text-align: left;
}

.process .feature-right p{
    padding-left: 15px;
    text-align: left;
}

/*-----Events-----*/
.events{
    padding: 100px 0;
    background: #efefef;
}
.event-box img{
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.5s;
}

.events h1{
    padding-bottom: 50px;
}

/*
.event-box img:hover{
    transform: scale(1.1);
}
*/

.event-box h4{
    display: block;
    color: #fff;
    text-shadow: -2px 2px 2px #000;
    font-weight: 600;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.event-box h6{
    display: block;
    color: #fff;
    text-shadow: -2px 2px 2px #000;
    font-weight: 400;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.event-box p{
    display: none;
    color: #fff;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(-5%, -50%);
}
.event-box{
    position: relative;
    margin-bottom: 30px;
}

.event-box img{
    height: 200px;
}

.event-box:hover img{
    filter:blur(4px);
    transform: scale(1.1);
    filter: brightness(30%);
}
.event-box:hover h4{
    display:none;
}
.event-box:hover h6{
    display: none;
}
.event-box:hover p{
    display: block;
}
@media screen and (min-width: 998px){
    .event-box p{
        font-size: 15px;
    }
}
@media screen and (max-width: 997px){
    .event-box p{
        font-size: 12px;
    }
}
/*------How To Help------*/
#howToHelp{
    background-image: linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)), url("./backgroundImages/Hoboken.jpg");
    background-size: cover;
    background-position: center;
    color: #efefef !important;
    background-attachment: fixed;
    padding: 100px 0;

}

#howToHelp h1{
    text-align: center;
    color: #efefef !important;
    padding-bottom: 10px;
}

#howToHelp h1::after{
    content: '';
    background: #efefef;
    display: block;
    height: 3px;
    width: 170px;
    margin: 20px auto 5px;
}

.services{
    margin-top: 40px;
}
.icon{
    font-size:40px;
    margin: 20px auto;
    padding: 0px;
    height: 80px;
    width: 80px;
    font-size: 60px;

}

#howToHelp p{
    font-size: 17px;
    margin-top: 20px;
    color: #ccc;
}

/*
.services .col-md-4:hover{
    background: #007bff;
    cursor: pointer;
    transition: 0.7s;
}
*/

.buttons-for-help{
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
}

.buttons-for-help .btn{
    color: #fff;
    background-color: #557a95;
    border: none;
    border-radius: 6px;
    margin: 20px;
}

#howToHelp .btn:hover{
    background-color: #666;
    cursor: pointer;
    transition: 0.2s;
}

/*------TEAM----------*/
#team{
    padding: 100px 0;
    padding-bottom: 50px;
    color: #555;
}
h1{
    text-align: center;
    color:#555!important;
    padding-bottom: 10px;
}
h1::after{
    content: '';
    background: #557a95;
    display: block;
    height: 3px;
    width: 170px;
    margin: 20px auto 5px;
}

.profile-pic{
    margin-top: 25px;
}

.profile-pic .img-box{
    opacity:1;
    display: block;
    position: relative;
}

.profile-pic .img-box img{
        border-radius: 50%;
}

.profile-pic h2{
    font-size: 30px;
    font-weight: bold;
    margin-top: 15px;
    color: #557a95 !important;
}

.profile-pic h3{
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    color: #5d5c61;
}
.profile-pic p{
    font-size: 17px;
    margin-top: 10px;
    color: #5d5c61;
}

/*
.img-box ul li{
    padding: 5px;
    display: inline-block;
}
*/

/*
.img-box:hover ul{
    opacity: 1;
}
*/

/*
.img-box ul, .img-box ul li{
    transition: 0.5s;
}
*/

/*-------CONTACT--------*/

#contact{
    background: #efefef;
    padding: 100px 0;

    color: #777;
}
.contact-form{
    padding: 15px;
}

.form-control{
    border-radius: 6px !important;
    border: none !important;
}

.contact-form .btn{
    color: #fff;
    background-color: #557a95;
    border: none;
    border-radius: 6px;
    margin: 20px;
}

#contact .btn:hover{
    background-color: #666;
    cursor: pointer;
    transition: 0.2s;
}

::placeholder{
    color: #999!important;
}

.follow{
    background: #fff;
    padding: 10px;
    margin-bottom: 22px;
    margin-top: 12px;
    border-radius: 6px;
}

.contact-info .fa{
    margin: 10px;
    color: #007bff;
    font-weight: bold;
}

/*--------footer--------*/
#footer{
    background-color: #333;
}
#footer p{
    color: white;
}
