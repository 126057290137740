*{
    margin: 0;
    padding: 0;
}


html{
    scroll-behavior: smooth;
}

/*-----MAIN BODY------*/

#head{
    width: 100%;

}
#head img{
    width: 100%;
    height: 100vh;
    filter: blur(30px);
    filter: brightness(40%);

}
.carousel-caption{
    top: 20%;
    bottom: initial!important;
}
/*FORMAT THIS PART */

.carousel-caption h5{
    color: #fff;
    font-size: 32px;
}
.carousel-item h2{
    color: #fff;
    font-size: 6em;
    letter-spacing: 0.07em;
    line-height: 1.7;
    font-family: "Slim-Joe";
    text-transform: uppercase;

}


.carousel-caption button:hover{
    animation: end;
}


.carousel-caption .btn-info {
  border-width: 2px;
  background-color: transparent;
  font-weight: 400;
  filter: alpha(opacity=80);
  padding: 8px 16px;
  border-color: #fff;
  color: #fff;
    font-size: 20px;
}

.carousel-caption .btn-info:hover, .btn-info:focus, .btn-info:active, .btn.active, .open > .btn.dropdown-toggle {
  background-color: transparent;
  color: #777777;
  border-color: #777777;
}

.carousel-caption .btn-info {
  border-radius: 2px;
  border-width: 1px;
  font-family: "Verlag-Book","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 600;
  line-height: 1;
}

/*----INFO-----*/

#information{
    background-color:#fff;
    padding: 40px;
}

#information .container{
    padding: 0px;
    background-color: #fff;
}

#information .container .row{
    padding: 0px;
    margin: 0;
}


#information img{
/*    width: 300px;   */
    border-radius: 10px;
}

#information .Jon{
    width: 100%;
}

.col-md-4{
    text-align: center;
    margin-right: 0px;
}

.col-md-4 h1{
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    color: #555;
}

.col-md-4 h2{
    font-size: 30px;
    font-weight: bold;
    margin-top: 5px;
    color: #557a95 !important;
}

.col-md-4 .bio{
    text-align: center;
    margin: 0;
    padding: 0;
    color: #5d5c61;
    font-size: 17px;
}

.col-md-4 .contact{
    text-align: left;
    margin: 0;
    padding: 0;
    color: #5d5c61;
    font-size: 17px;
}

.col-md-8{
    margin-right: 0px;
/*    margin-left: 50px;*/
}

.col-md-8 div{
    border: none;;
    margin: 1px;
    border-width: thin;
    border-radius: 6px;
    border-color: black;
}

.col-md-8 .about, .DTL, .details, .bring{
    padding-bottom: 0;
    margin-bottom: 1px;
}

.col-md-8 h1{
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    color: #555;
}

.col-md-8 h1::after{
    content: '';
    background: #557a95;
    display: block;
    height: 3px;
    width: 170px;
    margin: 10px auto 5px;
    margin-bottom: 20px;
}

.col-md-8 h2{
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: #555
}

.col-md-8 h2::after{
    content: '';
    background: #557a95;
    display: block;
    height: 3px;
    width: 170px;
    margin: 10px auto 5px;
    margin-bottom: 20px;
}


.col-md-8 p{
    text-align: center;
    margin: 0;
    padding: 0;
    color: #5d5c61;
    font-size: 17px;
}

/*
.col-md-7 {
    text-align: center;
}
*/
.col-md-8 h3{
    text-align: center;
}
.col-md-8 ul{
    list-style:circle;
    text-align: left;
    margin-left: 25%;
    padding: 0;
    color: #5d5c61;
    font-size: 17px;
}

/*----CONTACT-----*/
#contact{
    background: #efefef;
    padding: 100px 0;
    color: #777;
}
.contact-form{
    padding: 15px;
}

.form-control{
    border-radius: 6px !important;
    border: none !important;
}

.contact-form .btn{
    color: #fff;
    background-color: #557a95;
    border-radius: 6px;
}

#contact .btn:hover{
    background-color: #666;
    cursor: pointer;
    transition: 0.2s;
}

::placeholder{
    color: #999!important;
}

.follow{
    background: #fff;
    padding: 8px;
    margin: 12px;
    border-radius: 6px;
}

.contact-info .fa{
    margin: 10px;
    color: #007bff;
    font-weight: bold;
}
